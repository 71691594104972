<template>
<div>

    <v-dialog v-if="dialogServed" v-model="dialogServed" persistent width="100%">
        <v-card>
            <order-purcharse-served @onClose="dialogServed  = false; $refs.crud.refresh();" :value="parentID" :typeOrder="1" :order="{}" :viewLoad="true" />
        </v-card>
    </v-dialog>


    <v-row>
        <v-col md="12" lg="12" sm="12" class="pb-1">
            <s-crud group-by="OrdDocumentNumber" no-full height="400" excel sortable searchInput @pdf="pdf($event)" :config="this.config" ref="crud" :filter="filter" title="Recepción de OC/OS" @rowSelected="selectRows($event)">

                <template v-slot:options>
                    <div>
                        <v-btn title="Ver" elevation="0" v-if="item !== null && parentID > 0" x-small color="#5175af" @click="orderServed()">
                            <i class="fas fa-eye"></i>
                        </v-btn>
                    </div>

                </template>

                <template v-slot:filter>
                    <v-container>
                        <v-row justify="center">
                            <v-col lg="3" class="pt-0 pb-0" v-if="$fun.isAdmin()">
                                <s-select label="Usuario" placeholder="Digite Usuario" autofocus autocomplete clearable item-text="NtpFullName" v-model="filter.UsrCreateID" item-value="UsrID" :items="itemsUsers"></s-select>
                            </v-col>
                            <v-col lg="3" cols="12" class="pt-0 pb-0">
                                <c-provider v-model="filter.SupCode" />
                            </v-col>
                            <v-col lg="2" cols="12" class="pt-0 pb-0">
                                <s-date label="Fecha Inicio" v-model="filter.BeginDate" />
                            </v-col>
                            <v-col lg="2" cols="12" class="pt-0 pb-0">
                                <s-date label="Fecha Fin" nullable v-model="filter.EndDate" />
                            </v-col>
                            <v-col lg="2" cols="12" class="pt-0">
                                <s-select-definition :disabled="true" clearable nullable label="Estados" :def="1154" v-model="filter.OrdStatus" />
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
                <template v-slot:OrdStatusName="{ row }">
                    <v-tooltip bottom="">
                        <template v-slot:activator="{ on }">

                            <v-icon v-on="on" :color="
                                    row.OrdStatus == 2 ? 'brown' : 
                                    row.OrdStatus == 5 ? '' : ''
                                " style="font-size:16px;">
                                {{
                                row.OrdStatus == 2 ? 'fas fa-truck' : 
                                row.OrdStatus == 5 ? 'fas fa-lock' : ''
                            }}

                            </v-icon>
                            {{row.OrdStatusName}}
                        </template>
                        <span>{{row.OrdStatusName}}</span>

                    </v-tooltip>

                </template>
                <template v-slot:TypeOrder="{ row }">
                    <v-tooltip bottom="">
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" :color="
                                    row.TypeOrder == 1 ? 'red' : 'green'
                                " style="font-size:16px;">
                                {{
                                row.TypeOrder == 1 ? 'fas fa-car': 'fas fa-street-view'
                            }}

                            </v-icon>
                        </template>
                        <span>{{row.TypeOrder == 1 ? 'Compra': 'Servicio'}}</span>
                    </v-tooltip>
                </template>

            </s-crud>
        </v-col>

    </v-row>

    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

</div>
</template>

<script>
//Services
import _sOrderPurchase from "@/services/Logistics/OrderPurchaseAttendedService";
import _sPermit from "@/services/Logistics/PermitService";

//Components
import cProvider from "@/components/Logistics/cProvider.vue";
import OrderPurcharseServed from '@/views/Logistics/OrderPurchase/OrderPurcharseServed.vue';
/* import OrderPurchaseEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit.vue";
import OrderDispatch from '@/views/Logistics/OrderAttended/OrderDispatch.vue'; */

export default {
    components: {
        cProvider,
        OrderPurcharseServed,
       /*  OrderPurchaseEdit,
        OrderDispatch */
    },

    data: () => ({
        processing: false,

        messageProcessing: "",
        //NEW ORDER
        itemsUsers: [],
        //
        item: {},
        filter: {
            TypeOrder: 0,
            BeginDate: null,
            EndDate: null,
            OrdStatus: 5,
            SupCode: null,
            UsrCreateID: null
        },
        config: {
            model: {
                OttID: "ID",
                OttDate: "date",
                OrdStatusName: "",
                OrdStatus: "",
                SecUpdate: "date",
                OttTotalNew: "decimal",
                OrdStatusMail: "",
                OrdDocumentNumber: "string",
                TypeOrder: ""
            },
            service: _sOrderPurchase,
            headers: [{
                    text: "ID",
                    value: "OttID",
                    sortable: false
                },
                {
                    text: "Tipo",
                    value: "TypeOrder",
                    sortable: false
                },
                {
                    text: "Empresa",
                    value: "typeBusinessName",
                    width: 100,
                    sortable: false
                },
                {
                    text: "Documento",
                    value: "OttDocumentNumber",
                    width: 120,
                    sortable: false
                },
                {
                    text: "Nro. Doc.Orden",
                    value: "OrdDocumentNumber",
                    width: 120,
                    sortable: false
                },
                {
                    text: "Fecha",
                    value: "OttDate",
                    sortable: false
                },

                {
                    text: "Proveedor",
                    value: "SupName",
                    sortable: false
                },
                {
                    text: "Usuario",
                    value: "NtpFullName",
                    sortable: false
                },
                {
                    text: "Fecha Act.",
                    value: "SecUpdate",
                    sortable: false
                },
                {
                    text: "Estado",
                    value: "OrdStatusName",
                    sortable: false,
                    align: "left",
                },
                /* {
                    text: "Estado Despacho.",
                    value: "OttDispatchName",
                    sortable: false,
                    align: "center",
                    width: 20
                }, */
            ],
        },

        dialogServed: false,
        parentID: null,
        dialogShow: false,
    }),
    created() {
        _sPermit.users(this.$fun.getUserID()).then((r) => {
            this.itemsUsers = r.data;
        });
        this.filter.UsrID = this.$fun.isAdmin() ? null : this.$fun.getUserID();
    },

    watch: {
    },

    methods: {

        selectRows(items) {
            if (items.length > 0) {
                this.parentID = items[0].OrdID;
                this.item = items[0];
            } else {
                this.parentID = null;
                this.item = {}
            }

            console.log(this.item)
        },

        pdf(item) {
            this.processing = true
            item.TypePrint = 2
            _sOrderPurchase.PDF(item, this.$fun.getUserID()).then((r) => {
                    if (r.status == 200) {
                        this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, "Orden_compra");
                        this.processing = false
                    }

                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                });
        },

        orderServed() {
            this.dialogServed = true 
        },


    },

};
</script>
