import Service from "../Service";

const resource = "orderpurchase/";

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "paginationattended", parameters, {
            params: { requestID: requestID },
        });
    },
    PDF(dtr, requestID) {
        return Service.post(resource + "pdforderpurcharse", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },
    excel(dtr, requestID) {
        return Service.post(resource + "excel", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID: requestID },
        });
    },
};